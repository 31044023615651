import { IMAGES } from '../../constants/image'
import './SplashScreen.css'
import { DefaultScreenProps } from '../defaultScreenProps'

type SplashScreenProps = DefaultScreenProps & {
  onTrolleyClick: () => void
  onCowClick: () => void
}

export const SplashScreen = ({
  onTrolleyClick,
  onCowClick,
}: SplashScreenProps) => {
  return (
    <div className="splashScreenContainer">
      <img
        className="splashScreenImage"
        src={IMAGES.trolleyAndCow}
        alt="trolley and cow"
      />
      <div className="clickableAreas">
        <div className="trolleyArea" onClick={onTrolleyClick}></div>
        <div className="cowArea" onClick={onCowClick}></div>
      </div>
    </div>
  )
}
