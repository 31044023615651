export const VICTIMS = [
  {
    name: 'a capybara doing a handstand',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/capybaraDoingHandstand.png',
  },
  {
    name: 'Dwayne THE ROCK Johnson',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/dwayneTHEROCKJohnson.png',
  },
  {
    name: 'little orphan mice',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/littleOrphanMice.png',
  },
  {
    name: 'two babies in true love',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/twoBabiesInLove.png',
  },
  {
    name: 'a duck with a secret',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/duckWithASecret.png',
  },
  {
    name: 'a baby with a knife',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/babyWithKnife.png',
  },
  {
    name: 'a doctor on the verge of curing cancer',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/doctorOnVergeOfCure.png',
  },
  {
    name: 'your first ever pet alive again',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/firstEverPet.png',
  },
  {
    name: "your father's best friend",
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/fathersBestFriend.png',
  },
  {
    name: 'the very last polar bear',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/veryLastPolarBear.png',
  },
  {
    name: 'two babies with two knives',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/twoBabiesWithKnives.png',
  },
  {
    name: 'your loving spouse with a piping hot bowl of chowder',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/chowderSpouse.png',
  },
  {
    name: 'a bucket of fried chicken',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/bucketOfFriedChicken.png',
  },
  {
    name: 'the game show The Price is Right',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/thePriceIsRight.png',
  },
  {
    name: 'an Alex Trebek clone that is 5 times as large as the original',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/alexTrebek.png',
  },
  {
    name: 'a person about to be the index case of a new pandemic',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/indexCase.png',
  },
  {
    name: 'grandparents in love',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/grandparentsInLove.png',
  },
  {
    name: 'a group of cats',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/aGroupOfCats.png',
  },
  {
    name: 'a group of dogs',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/aGroupOfDogs.png',
  },
  {
    name: 'a guy wearing crocs with socks, four wheel drive engaged',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/guyWithCrocsWith4WD.png',
  },
  {
    name: 'a guy wearing crocs with socks, four wheel drive NOT engaged',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/guyWithCrosNo4WD.png',
  },
  {
    name: "a guy mansplaining his coworker's job to her",
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/guyMansplaining.png',
  },
  {
    name: "the guy who invented the phrase 'mansplaining'",
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/guyWhoInventedMansplaining.png',
  },
  {
    name: "the monster who left you on 'red'",
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/monsterLeftYouOnRead.png',
  },
  {
    name: "your neighbor who always wins the 'Best Yard' award",
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/bestNeighbor.png',
  },
  {
    name: "Jimmy Neutron's parents but they are mallard ducks",
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/jimmyNeutronParentsButDucks.png',
  },
  {
    name: 'a group of children bullying a cow',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/kidsBullyingCow.png',
  },
  {
    name: 'a group of cows bullying a child',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/cowsBullyingKid.png',
  },
  {
    name: 'your high school principal',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/yourHighscoolPrincipal.png',
  },
  {
    name: 'a group of clowns who moonlight as hitmen',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/clownsMoonlightAsHitmen.png',
  },
  {
    name: 'your least favorite relative',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/leastFavoriteRelative.png',
  },
  {
    name: 'your least favorite coworker',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/leastFavoriteCoworker.png',
  },
  {
    name: 'your least favorite politician',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/leastFavoritePolitician.png',
  },
  {
    name: '3 elephants of drastically different size',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/threeElephantsOfDifferentSize.png',
  },
  {
    name: 'a mule with a knowing smile',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/muleWithKnowingSmile.png',
  },
  {
    name: 'the actor from the most recent Spiderman movie',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/mostRecentSpiderMan.png',
  },
  {
    name: 'Hobbits having a party',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/hobbitsHavingAParty.png',
  },
  {
    name: 'a dolphin that had evolved to walk on land',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/dolphinWithLegs.png',
  },
  {
    name: 'a brisket that has been cooking ALL DAY',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/briksetAllDay.png',
  },
  {
    name: 'someone who looks eerily like you',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/someoneLooksLikeYou.png',
  },
  {
    name: 'a dog that grows 2% bigger every day and lives to be 100',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/dogKeepsGrowing.png',
  },
  {
    name: 'an otter with an exquisite taste in jewelry',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/otterWithJewelry.png',
  },

  {
    name: 'a sloth robbing a corner store',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/slothRobbingStore.png',
  },
  {
    name: 'a fish who dreams of flying',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/fishDreamsOfFlying.png',
  },
  {
    name: 'a giraffe drop-kicking a kid in a wheelchair',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/giraffeWheelchair.png',
  },
  {
    name: "a dog who thinks it's a shep",
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/dogTHinksItIsSheep.png',
  },
  {
    name: 'a spider knitting scarves',
    appearances: 1,
    deathSentences: 1,
    image: 'spider',
  },
  {
    name: 'a group of goats eating the U.S. Constitution',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/goatEatsConstitution.png',
  },
  {
    name: 'your sweet mother as a seagull',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/motherSeagall.png',
  },
  {
    name: 'a group of pandas who refuse to mate',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/pandasNoMate.png',
  },
  {
    name: 'a big yellow lizard who keeps shooting cannon balls at you',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/yellowLizard.png',
  },
  {
    name: 'a retired astronaut who never got to go to space',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/retiredAstronaut.png',
  },
  {
    name: 'a sentient toilet',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/sentientToilet.png',
  },
  {
    name: 'a hypochondriac who is in legitamate danger',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/hypochondriacInDanger.png',
  },
  {
    name: 'a horse with a human mind who rides a man with a horse mind all across the West',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/horseRidesMan.png',
  },
  {
    name: "everyone's favorite big purple dinosaur",
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/everyonesFavoriteBigPurpleDinosaur.png',
  },
  {
    name: 'the manta ray that killed Steve Irwin',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/theMantaRayThatKilledSteveIrwin.png',
  },
  {
    name: 'Steve Irwin (but you are a manta ray)',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/SteveIrwinButYouAreA+MantaRay.png',
  },
  {
    name: 'the last white rhino in the world',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/theLastWhiteRhinoInTheWorld.png',
  },
  {
    name: 'your dentist',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/yourDentist.png',
  },
  {
    name: 'the Lochness monster',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/theLochnessMonster.png',
  },
  {
    name: 'the first alien to make contact with Earth',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/theFirstAlienToMakeContactWithEarth.png',
  },
  {
    name: "your mother's famous 7 layer dip",
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/yourMothersFamous7LayerDip.png',
  },
  {
    name: 'someone who actually pronounces it - pa tah toh',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/someoneWhoActuallyPronouncesPaTaToh.png',
  },
  {
    name: 'a boy just out of a coma claiming proof of the afterlife',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/aBoyJustOutComa.png',
  },
  {
    name: 'the ghost of Guy Fieti',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/ghostOfGuyFieti.png',
  },
  {
    name: 'a little girl stuck in a well',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/girlInWell.png',
  },
  {
    name: 'a cat the shape of a watermelon',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/catWatermelon.png',
  },
  {
    name: 'someone with toes instead of fingers and fingers instead of toes',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/fingerToesToeFingers.png',
  },
  {
    name: 'a large wheel of cheese',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/largeCheeseWheel.png',
  },
  {
    name: 'your childhood imaginary friend come to life',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/imaginaryFriendToLife.png',
  },
  {
    name: 'the fastest cow in the world',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/fastestCow.png',
  },
  {
    name: 'a human centipede',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/humanCentipede.png',
  },
  {
    name: 'a guy saying Mmul',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/guySayyingMmul.png',
  },
  {
    name: 'a little lad riding a Chihuahua',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/littleLadyOnChiahuhu.png',
  },
  {
    name: 'a dog with 100 legs',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/dogLegs.png',
  },
  {
    name: 'a One-Wheel convention',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/oneWheelConvention.png',
  },
  {
    name: 'a billionaire who make believes as an astronaut',
    appearances: 1,
    deathSentences: 1,
    image:
      'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/jeffBezos.png',
  },
]

export const newVictimsToAdd = []

export const DefaultVictimOne = VICTIMS[0]
export const DefaultVictimTwo = VICTIMS[1]
