import { IMAGES } from '../../constants/image'
import './IntroScreen.css'
import { DefaultScreenProps } from '../defaultScreenProps'

type IntroScreenProps = DefaultScreenProps & {
  sayIntroCopy: () => void
}

export const IntroScreen = ({ sayIntroCopy }: IntroScreenProps) => {
  return (
    <div className="introScreenContainer">
      <img
        className="introScreenImage"
        src={IMAGES.intro}
        onClick={sayIntroCopy}
        alt="angry looking trolley"
      />
    </div>
  )
}
