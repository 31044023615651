export const IMAGES = {
  trolleyAndCow:
    'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/trolleyTitle2.png',
  trolley:
    'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/trolleyTitle.png',
  intro:
    'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/trolleyIntro.png',
}

export const VICTIM_IMAGE = {
  veryLastPolarBear:
    'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/veryLastPolarBear.png',
  babyWithKnife:
    'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/babyWithKnife.png',
  firstEverPet:
    'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/firstEverPet.png',
  grandparentsInLove:
    'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/grandparentsInLove.png',
  chowerSpouse:
    'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/chowderSpouse.png',
  twoBabiesWithKnives:
    'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/twoBabiesWithKnives.png',
}

export const VICTIM_FACEOFF = {
  babyWithKnifeXveryLastPolarBear:
    'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/babyWithKnifeVveryLastPolarBear.png',
  firstEverPetXgrandparentsInLove:
    'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/grandparentsVfirstPet.png',
  chowerSpouseXtwoBabiesWithKnives:
    'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/chowderSpouseVknifeBabies.png',
}

export const TROLLEY_CRASH = {
  veryLastPolarBear:
    'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/polarBearTrolleyCrash.png',
  grandparentsInLove:
    'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/grandparentTrolleyCrash.png',
  twoBabiesWithKnives:
    'https://custom-skills-public.s3.amazonaws.com/trollilopolis/images/twoBabieTrolleyCrash.png',
}
