type AudioTask = () => Promise<void>

export class AudioQueueManager {
  private queue: AudioTask[] = []
  private isPlaying: boolean = false

  public enqueue(task: AudioTask) {
    this.queue.push(task)
    if (!this.isPlaying) {
      this.playNext()
    }
  }

  private async playNext() {
    if (this.queue.length === 0) {
      this.isPlaying = false
      return
    }

    this.isPlaying = true
    const task = this.queue.shift()
    if (task) {
      await task()
    }
    this.playNext()
  }
}
