export const COPY = {
  ERRPR:
    'Something has gone seriously afoul. Our apologies, please try again later ',
  LAST_WORD: 'Real nice of you back there. Now get lost, murderer! ',
  INTRO_1: 'In Trolley lopolis... WE TAKE NO PRISONERS! You are a big, ',
  INTRO_2:
    ' bumbling, rumbling choo choo chonker Trolley. Time to decide who lives and who dies! ',
  WHO_LIVES_QUESTION:
    'who WILL YOU CHUGGGA CHUGGA CHOOSE?! Will you veer Left or Right?',
}

export const DYNAMIC_COPY = {
  LEFT_VICTIM_INTRO: (victimName: string) =>
    `If you veer to the left, you kill ${victimName}.`,
  RIGHT_VICTIM_INTRO: (victimName: string) =>
    `Veer to the right, you kill ${victimName}.`,
  KILLED_NUMBER: (victimName: string, numTimesKilled: string) =>
    `You were the ${numTimesKilled} bastard to murder ${victimName}.`,
  KILLED_PERCENT: (victimName: string, percentTimesKilled: string) =>
    `Poor old ${victimName} gets killed ${percentTimesKilled} percent of the time.`,
  WHO_LIVES_FALLBACK: (leftVictim: string, rightVictim: string) =>
    `Say "Left" to kill ${leftVictim}. Say "Right" to kill ${rightVictim}.`,
}

export const COPY_VARIATION = {
  ROLLING_AGAIN: [
    `Oh no the trolley won't stop!`,
    `Trolley by trolley they fall...`,
    `THE TROLLEY BARELY SLOWED!`,
    `Good God, wait what's that up ahead?`,
    `Like sand through an hourglass, so are our lives.`,
    `This trolley of the damned keeps chugging!`,
    `Looks like the brakes took a permanent vacation!`,
    `If you see your life flashing before your eyes, it's just the reflection off the shiny front of the trolley.`,
    `It's a bird, it's a plane, nope, wtf it a trolley.`,
    `If you can't stand the heat, get off the tracks!`,
    `Roses are red, violets are blue, this trolley outta control, and comin right at you!`,
    `You shoulda taken the bus today!`,
    `The trolley may be outta control, but at least it's on schedule- next arrival RIGHT NOW!`,
    `If you got a God it's praying time!`,
    `You'd better finish up your deal with the Devil cause the trolley's a comin fast!`,
    `chugga CHUGGA chugga CHUGGA oh fuuuuu--!`,
  ],
}

export const DYNAMIC_COPY_VARIATION = {
  SENTENCE_VICTIM: (victimName: string) => [
    `Ya hear that, ${victimName}? God said TIME TO DIE!`,
    `${victimName} you've been sentenced TO DEATH!`,
    `Sorry, ${victimName}, you're taking the TROLLEY TO HELL!`,
    `Woh, ${victimName}! Watch out for that-!`,
    `From on high its time to die ${victimName}!`,
    `Satan's got ya ankles, ${victimName}! And he's gonna twist them!`,
    `The countdown to your demise has officially begun, ${victimName}. Tick tock...`,
    `Pack your bags, ${victimName}, you're heading to the afterlife!`,
    `I hope you made peace with your maker, ${victimName}, because you're about to meet them!`,
    `The end is near, ${victimName}. Can you feel it?`,
    `I hope you lived a good life, ${victimName}, because this is the end of the road!`,
    `Don't be afraid, ${victimName}, death is just a part of life. Embrace it.`,
    `You can run, ${victimName}, but you can't run faster than the trolley!`,
    `Time to pay the piper, ${victimName}!`,
    `Don't worry, ${victimName}, death is painless... BUT TROLLEYS AIN'T!`,
    `It's a sad day for you, ${victimName} but a good day for the TROLLEY OF DOOM!`,
    `The bell tolls for thee, ${victimName}!`,
  ],
}
