import './WhoLivesQuestionScreen.css'
import { DefaultScreenProps } from '../defaultScreenProps'
import { useState } from 'react'

type WhoLivesQuestionScreenProps = DefaultScreenProps & {
  sayVictimIntro: () => void
  promptWhoLives: () => void
  getVictimImages: () => { leftVictim: string; rightVictim: string }
  handleLeftClick: () => void
  handleRightClick: () => void
}

export const WhoLivesQuestionScreen = ({
  sayVictimIntro,
  promptWhoLives,
  getVictimImages,
  handleLeftClick,
  handleRightClick,
}: WhoLivesQuestionScreenProps) => {
  const [audioPlayed, setAudioPlayed] = useState(false)

  const playAudio = () => {
    setAudioPlayed(true)
    sayVictimIntro()
    promptWhoLives()
  }
  const { leftVictim, rightVictim } = getVictimImages()
  return (
    <div className="whoLivesQuestionScreenContainer">
      {!audioPlayed && <button onClick={playAudio}>play God?</button>}
      {audioPlayed && (
        <div className="clickableAreas">
          <img
            className="leftVictimImage"
            src={leftVictim}
            alt="left victim"
            onClick={() => handleLeftClick()}
          />
          <img
            className="rightVictimImage"
            src={rightVictim}
            alt="right victim"
            onClick={() => handleRightClick()}
          />
        </div>
      )}
    </div>
  )
}
