import { ISpeechService } from 'src/services/SpeechService/SpeechService'
import { AUDIO } from '../constants/audio'
import { Scenes } from '../constants/game'
import { IAudioPlayerService } from '../services/AudioPlayerService/IAudioPlayerService'
import { IExecutor } from './IExecutor'
import { COPY } from 'src/constants/copy'
import { AudioQueueManager } from 'src/services/AudioQueueManagerService/AudioQueueManagerService'

export class IntroSceneExecutor extends IExecutor {
  private audioPlayer: IAudioPlayerService
  private setScene: (scene: Scenes) => void
  private speech: ISpeechService
  private audioQueueManager: AudioQueueManager

  constructor(
    audioPlayer: IAudioPlayerService,
    setScene: (scene: Scenes) => void,
    speech: ISpeechService,
    audioQueueManager: AudioQueueManager,
  ) {
    super()
    this.audioPlayer = audioPlayer
    this.setScene = setScene
    this.speech = speech
    this.audioQueueManager = audioQueueManager
  }

  public exec = async () => {
    console.log('🐄 SplashScreenSceneExecutor exec')
  }

  public sayIntroCopy = async () => {
    this.audioQueueManager.enqueue(() =>
      this.audioPlayer.playSound(AUDIO.TrolleyRumbleShort),
    )
    this.audioQueueManager.enqueue(() => this.speech.speak(COPY.INTRO_1))
    this.audioQueueManager.enqueue(() => this.speech.speak(COPY.INTRO_2))
    this.audioQueueManager.enqueue(() =>
      this.audioPlayer.playSound(AUDIO.ShortFemScream1),
    )

    this.audioQueueManager.enqueue(async () => {
      this.setScene(Scenes.WhoLivesQuestion)
    })
  }
}
