import { ISpeechService } from 'src/services/SpeechService/SpeechService'

import { Scenes } from '../constants/game'
import { IAudioPlayerService } from '../services/AudioPlayerService/IAudioPlayerService'
import { IExecutor } from './IExecutor'
import { DYNAMIC_COPY_VARIATION } from 'src/constants/copy'
import { VictimService } from 'src/services/VictimService/VictimService'
import { AUDIO_SCREAMS } from 'src/constants/audio'
import { AudioQueueManager } from 'src/services/AudioQueueManagerService/AudioQueueManagerService'
import { getRandomCopyVariation } from 'src/utils/getRandomCopyVariation'
import { DefaultVictimOne } from 'src/constants/victim'

export class SentenceVictimSceneExecutor extends IExecutor {
  private audioPlayer: IAudioPlayerService
  private setScene: (scene: Scenes) => void
  private speech: ISpeechService
  private audioQueueManager: AudioQueueManager

  private victimService: VictimService

  constructor(
    audioPlayer: IAudioPlayerService,
    setScene: (scene: Scenes) => void,
    speech: ISpeechService,
    audioQueueManager: AudioQueueManager,
    victimService: VictimService,
  ) {
    super()
    this.audioPlayer = audioPlayer
    this.setScene = setScene
    this.speech = speech
    this.audioQueueManager = audioQueueManager
    this.victimService = victimService
  }

  public exec = async () => {
    console.log('🐄 SentenceVictimSceneExecutor exec')
  }

  public saySentenceVictim = async () => {
    const randomSentenceCopy = getRandomCopyVariation(
      DYNAMIC_COPY_VARIATION.SENTENCE_VICTIM(
        this.victimService.doomedVictim?.name ?? DefaultVictimOne.name,
      ),
    )
    const randomScream = getRandomCopyVariation(AUDIO_SCREAMS)

    this.audioQueueManager.enqueue(() =>
      this.audioPlayer.playSound(randomScream),
    )

    this.audioQueueManager.enqueue(() => this.speech.speak(randomSentenceCopy))

    this.audioQueueManager.enqueue(async () => {
      this.setScene(Scenes.DecisionStats)
    })
  }

  public getVictimImage = () => {
    return this.victimService.doomedVictim?.image ?? DefaultVictimOne.image
  }
}
