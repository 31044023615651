import './DecisionStatsScreen.css'
import { DefaultScreenProps } from '../defaultScreenProps'
import { useEffect, useRef } from 'react'
import { IMAGES } from 'src/constants/image'

type DecisionStatsScreenProps = DefaultScreenProps & {
  sayVictimStats: () => void
  getVictimImage: () => string
}

export const DecisionStatsScreen = ({
  sayVictimStats,
  getVictimImage,
}: DecisionStatsScreenProps) => {
  const audioPlayedRef = useRef(false)

  useEffect(() => {
    if (!audioPlayedRef.current) {
      sayVictimStats()
      audioPlayedRef.current = true
    }
  }, [sayVictimStats])

  return (
    <div className="decisionStatsScreenContainer">
      <div className="victimTrolleyContainer">
        <img className="victimImage" src={getVictimImage()} alt="left victim" />
        <img className="trolleyImage" src={IMAGES.trolley} alt="angryTrolley" />
      </div>
    </div>
  )
}
