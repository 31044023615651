export enum TimesCompleted {
  None = 0,
  Once = 1,
  Twice = 2,
  Thrice = 3,
  FourTimes = 4,
  FiveTimes = 5,
  SixTimes = 6,
  SevenTimes = 7,
}

export enum WhoLivesAnswer {
  Left = 'left',
  Right = 'right',
}

export enum Scenes {
  Splash = 'Splash',
  Intro = 'Intro',
  WhoLivesQuestion = 'WhoLivesQuestion',
  WhoLivesAnswer = 'WhoLivesAnswer',
  SetenceVictim = 'SentenceVictim',
  DecisionStats = 'DecisionStats',
  Exit = 'Exit',
}
