import { AudioPlayerService } from '../services/AudioPlayerService/AudioPlayerService'
import { IAudioPlayerService } from '../services/AudioPlayerService/IAudioPlayerService'
import { Scenes } from '../constants/game'
import { IExecutor } from './IExecutor'
import { IntroSceneExecutor } from './IntroSceneExecutor'
import { SplashScreenSceneExecutor } from './SplashScreenSceneExecutor'
import { ISpeechService } from 'src/services/SpeechService/SpeechService'
import { SpeechService } from 'src/services/SpeechService/ISpeechService'

import { VictimService } from 'src/services/VictimService/VictimService'
import { WhoLivesQuestionSceneExecutor } from './WhoLivesQuestionSceneExecutor'
import { AudioQueueManager } from 'src/services/AudioQueueManagerService/AudioQueueManagerService'
import { SentenceVictimSceneExecutor } from './SentenceVictimSceneExecutor'
import { DecisionStatsSceneExecutor } from './DecisionStatsSceneExecutor'

export class AppExecutor extends IExecutor {
  private splashScreenSceneExecutor
  private introSceneExecutor
  private whoLivesQuestionExecutor
  private sentenceVictimExecutor
  private decisionStatsSceneExecutor

  private audioPlayer: IAudioPlayerService
  private speech: ISpeechService
  private audioQueueManager: AudioQueueManager
  private victimService: VictimService

  constructor(setScene: (scene: Scenes) => void) {
    super()
    this.audioPlayer = new AudioPlayerService()
    this.audioPlayer.init()
    this.speech = new SpeechService()
    this.speech.init()
    this.audioQueueManager = new AudioQueueManager()
    this.victimService = new VictimService()

    this.splashScreenSceneExecutor = new SplashScreenSceneExecutor(
      this.audioPlayer,
      setScene,
      this.audioQueueManager,
    )

    this.introSceneExecutor = new IntroSceneExecutor(
      this.audioPlayer,
      setScene,
      this.speech,
      this.audioQueueManager,
    )

    this.whoLivesQuestionExecutor = new WhoLivesQuestionSceneExecutor(
      this.audioPlayer,
      setScene,
      this.speech,
      this.audioQueueManager,
      this.victimService,
    )

    this.sentenceVictimExecutor = new SentenceVictimSceneExecutor(
      this.audioPlayer,
      setScene,
      this.speech,
      this.audioQueueManager,
      this.victimService,
    )

    this.decisionStatsSceneExecutor = new DecisionStatsSceneExecutor(
      setScene,
      this.speech,
      this.audioQueueManager,
      this.victimService,
    )
  }

  public exec = async () => {
    console.log('🐄 App exec')
  }

  public getSplashScreenProps = () => ({
    onTrolleyClick: this.splashScreenSceneExecutor.handleTrolleyClick,
    onCowClick: this.splashScreenSceneExecutor.handleCowClick,
  })

  public getIntroScreenProps = () => ({
    sayIntroCopy: this.introSceneExecutor.sayIntroCopy,
  })

  public getWhoLivesQuestionScreenProps = () => ({
    sayVictimIntro: this.whoLivesQuestionExecutor.sayVictimIntro,
    promptWhoLives: this.whoLivesQuestionExecutor.promptWhoLives,
    getVictimImages: this.whoLivesQuestionExecutor.getVictimImages,
    handleLeftClick: this.whoLivesQuestionExecutor.handleLeftClick,
    handleRightClick: this.whoLivesQuestionExecutor.handleRightClick,
  })

  public getSentenceVictimScreenProps = () => ({
    saySentenceVictim: this.sentenceVictimExecutor.saySentenceVictim,
    getVictimImage: this.sentenceVictimExecutor.getVictimImage,
  })

  public getDecisionStatsScreenProps = () => ({
    sayVictimStats: this.decisionStatsSceneExecutor.sayVictimStats,
    getVictimImage: this.decisionStatsSceneExecutor.getVictimImage,
  })
}
