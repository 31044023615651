import { Scenes } from '../constants/game'
import { AUDIO } from '../constants/audio'
import { IAudioPlayerService } from '../services/AudioPlayerService/IAudioPlayerService'
import { IExecutor } from './IExecutor'
import { AudioQueueManager } from 'src/services/AudioQueueManagerService/AudioQueueManagerService'

export class SplashScreenSceneExecutor extends IExecutor {
  private audioPlayer: IAudioPlayerService
  private setScene: (scene: Scenes) => void
  private audioQueueManager: AudioQueueManager

  constructor(
    audioPlayer: IAudioPlayerService,
    setScene: (scene: Scenes) => void,
    audioQueueManager: AudioQueueManager,
  ) {
    super()
    this.audioPlayer = audioPlayer
    this.setScene = setScene
    this.audioQueueManager = audioQueueManager
  }

  public exec = async () => {
    console.log('🐄 SplashScreenSceneExecutor exec')
  }

  public handleTrolleyClick = () => {
    this.audioQueueManager.enqueue(() =>
      this.audioPlayer.playSound(AUDIO.BeepBeep),
    )
    this.audioQueueManager.enqueue(async () => {
      this.setScene(Scenes.Intro)
    })
  }

  public handleCowClick = () => {
    window.location.href = 'https://www.heifferopol.is/'
  }
}
