import { ISpeechService } from './SpeechService'

export class SpeechService implements ISpeechService {
  private isInitialized = false

  public init() {
    if ('speechSynthesis' in window) {
      this.isInitialized = true
    } else {
      console.error('🤡 🤡 🤡 SpeechService is not supported in this browser.')
    }
  }

  public async speak(text: string): Promise<void> {
    if (!this.isInitialized) {
      return
    }

    return new Promise<void>((resolve, reject) => {
      const utterance = new SpeechSynthesisUtterance(text)

      utterance.onend = () => {
        resolve()
      }

      utterance.onerror = (event) => {
        console.error('SpeechSynthesisUtterance.onerror', event)
        reject(new Error('🤡 🤡 🤡  Failed to synthesize speech'))
      }

      window.speechSynthesis.speak(utterance)
    })
  }
}
