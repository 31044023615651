import { useState } from 'react'

import './SentenceVictimScreen.css'
import { DefaultScreenProps } from '../defaultScreenProps'

type SentenceVictimScreenProps = DefaultScreenProps & {
  saySentenceVictim: () => void
  getVictimImage: () => string
}

export const SentenceVictimScreen = ({
  saySentenceVictim,
  getVictimImage,
}: SentenceVictimScreenProps) => {
  const [audioPlayed, setAudioPlayed] = useState(false)

  if (!audioPlayed) {
    saySentenceVictim()
    setAudioPlayed(true)
  }

  const victim = getVictimImage()

  return (
    <div className="sentenceVictimScreenContainer">
      <img
        className="sentencedVictimImage"
        src={victim}
        alt="selected victim"
      />
    </div>
  )
}
