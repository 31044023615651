import { ISpeechService } from 'src/services/SpeechService/SpeechService'

import { Scenes } from '../constants/game'

import { IExecutor } from './IExecutor'
import { DYNAMIC_COPY } from 'src/constants/copy'
import { VictimService } from 'src/services/VictimService/VictimService'

import { AudioQueueManager } from 'src/services/AudioQueueManagerService/AudioQueueManagerService'
import { getOrdinalSuffix } from 'src/utils/getOrdinalSuffix'
import { DefaultVictimOne } from 'src/constants/victim'

export class DecisionStatsSceneExecutor extends IExecutor {
  private setScene: (scene: Scenes) => void
  private speech: ISpeechService
  private audioQueueManager: AudioQueueManager

  private victimService: VictimService

  constructor(
    setScene: (scene: Scenes) => void,
    speech: ISpeechService,
    audioQueueManager: AudioQueueManager,
    victimService: VictimService,
  ) {
    super()
    this.setScene = setScene
    this.speech = speech
    this.audioQueueManager = audioQueueManager
    this.victimService = victimService
  }

  public exec = async () => {
    console.log('🐄 DecisionStatsSceneExecutor exec')
  }

  public sayVictimStats = async () => {
    const numTimesKilledToOrdinal = getOrdinalSuffix(
      this.victimService.getNumTimesKilled(),
    )

    const murderVictimName =
      this.victimService.doomedVictim?.name ?? DefaultVictimOne.name

    this.audioQueueManager.enqueue(() =>
      this.speech.speak(
        DYNAMIC_COPY.KILLED_NUMBER(murderVictimName, numTimesKilledToOrdinal),
      ),
    )

    this.audioQueueManager.enqueue(() =>
      this.speech.speak(
        DYNAMIC_COPY.KILLED_PERCENT(
          murderVictimName,
          this.victimService.getPercentKilled(),
        ),
      ),
    )

    this.audioQueueManager.enqueue(async () => {
      this.setScene(Scenes.WhoLivesQuestion)
    })
  }

  public getVictimImage = () => {
    return this.victimService.doomedVictim?.image ?? DefaultVictimOne.image
  }
}
