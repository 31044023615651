import { Scenes } from '../constants/game'
import { create } from 'zustand'

type SceneState = {
  currentScene: Scenes
  setScene: (scene: Scenes) => void
}

// Create the store directly using `create`
export const useSceneStore = create<SceneState>((set) => ({
  currentScene: Scenes.Splash,
  setScene: (scene) => set({ currentScene: scene }),
}))
