export const getOrdinalSuffix = (numberStr: string): string => {
  const number: number = parseInt(numberStr, 10)
  const suffixes: string[] = ['th', 'st', 'nd', 'rd']
  const lastDigit: number = number % 10
  const suffix: string =
    lastDigit <= 3 && number !== 11 && number !== 12 && number !== 13
      ? suffixes[lastDigit]
      : suffixes[0]
  return numberStr + suffix
}
