export const AUDIO = {
  BeepBeep:
    'https://custom-skills-public.s3.amazonaws.com/trollilopolis/ES_Trolley+Horn+Beep+-+SFX+Producer.mp3',
  TrolleyRumbleShort:
    'https://custom-skills-public.s3.amazonaws.com/trollilopolis/trolleyRumbleShort.mp3',
  TrolleyRumbleShort2:
    'https://custom-skills-public.s3.amazonaws.com/trollilopolis/trolleyrumbleshort2_4uiuAfa1.mp3',
  ShortFemScream1:
    'https://custom-skills-public.s3.amazonaws.com/trollilopolis/shortFemScream.mp3',
  ShortMalScream1:
    'https://custom-skills-public.s3.amazonaws.com/trollilopolis/shortMalScream.mp3',
  ShortFemScream2:
    'https://custom-skills-public.s3.amazonaws.com/trollilopolis/shortFemScream.mp3',
  ShortMalScream2:
    'https://custom-skills-public.s3.amazonaws.com/trollilopolis/shortFemScream2.mp3',
  TrolleyCrash1:
    'https://custom-skills-public.s3.amazonaws.com/trollilopolis/trolleyCrash1.mp3',
  TrolleyCrash2:
    'https://custom-skills-public.s3.amazonaws.com/trollilopolis/trolleyCrash2.mp3',
  TrolleyCrash3:
    'https://custom-skills-public.s3.amazonaws.com/trollilopolis/trolleyCrash3.mp3',
}

export const AUDIO_SCREAMS = [
  AUDIO.ShortFemScream1,
  AUDIO.ShortMalScream1,
  AUDIO.ShortFemScream2,
  AUDIO.ShortMalScream2,
]

export const AUDIO_TROLLEY_CRASHES = [
  AUDIO.TrolleyCrash1,
  AUDIO.TrolleyCrash2,
  AUDIO.TrolleyCrash3,
]
