import { ISpeechService } from 'src/services/SpeechService/SpeechService'

import { Scenes } from '../constants/game'
import { IAudioPlayerService } from '../services/AudioPlayerService/IAudioPlayerService'
import { IExecutor } from './IExecutor'
import { COPY, DYNAMIC_COPY } from 'src/constants/copy'
import { VictimService } from 'src/services/VictimService/VictimService'
import { AUDIO } from 'src/constants/audio'
import { AudioQueueManager } from 'src/services/AudioQueueManagerService/AudioQueueManagerService'

export class WhoLivesQuestionSceneExecutor extends IExecutor {
  private audioPlayer: IAudioPlayerService
  private setScene: (scene: Scenes) => void
  private speech: ISpeechService
  private audioQueueManager: AudioQueueManager

  private victimService: VictimService

  constructor(
    audioPlayer: IAudioPlayerService,
    setScene: (scene: Scenes) => void,
    speech: ISpeechService,
    audioQueueManager: AudioQueueManager,
    victimService: VictimService,
  ) {
    super()
    this.audioPlayer = audioPlayer
    this.setScene = setScene
    this.speech = speech
    this.audioQueueManager = audioQueueManager
    this.victimService = victimService
  }

  public exec = async () => {
    console.log('🐄 WhoLivesQuestionSceneExecutor exec')
    this.victimService.getVictimsFromDB()
  }

  public sayVictimIntro = async () => {
    this.audioQueueManager.enqueue(() =>
      this.speech.speak(
        DYNAMIC_COPY.LEFT_VICTIM_INTRO(this.victimService.victimOne.name),
      ),
    )

    this.audioQueueManager.enqueue(() =>
      this.speech.speak(
        DYNAMIC_COPY.RIGHT_VICTIM_INTRO(this.victimService.victimTwo.name),
      ),
    )
  }

  public promptWhoLives = async () => {
    this.audioQueueManager.enqueue(() =>
      this.speech.speak(COPY.WHO_LIVES_QUESTION),
    )
  }

  public getVictimImages = () => {
    return {
      leftVictim: this.victimService.victimOne.image,
      rightVictim: this.victimService.victimTwo.image,
    }
  }

  public handleLeftClick = () => {
    this.victimService.doomVictim(this.victimService.victimOne.name)

    this.audioQueueManager.enqueue(() =>
      this.audioPlayer.playSound(AUDIO.TrolleyCrash1),
    )

    this.audioQueueManager.enqueue(async () => {
      this.setScene(Scenes.SetenceVictim)
    })
  }

  public handleRightClick = () => {
    this.victimService.doomVictim(this.victimService.victimTwo.name)

    this.audioQueueManager.enqueue(() =>
      this.audioPlayer.playSound(AUDIO.TrolleyCrash2),
    )

    this.audioQueueManager.enqueue(async () => {
      this.setScene(Scenes.SetenceVictim)
    })
  }
}
