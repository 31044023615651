export abstract class IExecutor {
  constructor() {
    this.init()
  }

  private init(): void {
    console.log(`🐄 ${this.constructor.name}`)
  }

  public abstract exec(): Promise<void>
}
